<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { OffsetItem } from '~/data/OffsetItem'

    interface Props {
        image?: Asset
        content: string
        faded?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        faded: true,
    })

    const titleOffset = new OffsetGroup(new OffsetItem(10, 3), new OffsetItem(3, 0))
    const contentOffset = new OffsetGroup(new OffsetItem(3, 6), new OffsetItem(3, 3))

    const introArea = ref()
    const intro = ref()

    onMounted(() => {
        if (!import.meta.client) {
            // only run this client-side
            return
        }

        let range = 0

        if (!props.image) {
            // no feature image
            range = document.documentElement.clientHeight / 2
        } else {
            const margin = parseInt(
                getComputedStyle(intro.value).getPropertyValue('--feature-image-margin'),
                10,
            )

            range = Math.round(
                introArea.value.clientHeight -
                    intro.value.clientHeight -
                    intro.value.getBoundingClientRect().top -
                    margin,
            )
        }

        if (range > 0) {
            document.documentElement.style.setProperty('--animation-range', `${range}px`)
        }
    })
</script>

<template>
    <div
        class="page-header relative overflow-hidden"
        :class="{ faded, 'with-image mb-12 text-white lg:mb-16 xl:mb-24': !!image }"
    >
        <Asset
            v-if="image"
            :asset="image"
            fit="cover"
            class="h-svh w-full opacity-0 sm:aspect-[7/5] sm:h-auto md:aspect-[8/5] lg:aspect-video"
            data-aos="fade-up"
        />

        <div
            class="layout-grid pointer-events-none absolute left-0 right-0 top-6 z-50 mt-20 uppercase transition md:mt-0 lg:fixed"
            :class="{ 'opacity-0': useNavOpen().value }"
        >
            <div
                class="pointer-events-auto relative text-xl font-bold opacity-0 md:mr-20 lg:left-12 lg:order-last lg:mr-0 xl:left-0"
                :class="titleOffset.classes"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <slot />
            </div>
        </div>

        <div
            ref="introArea"
            class="page-intro-area inset-0 z-10 h-full py-6"
            :class="{ absolute: !!image }"
        >
            <div class="layout-grid mt-20 uppercase transition-colors md:mt-0">
                <div
                    ref="intro"
                    v-html="content"
                    class="page-intro raw-content mb-8 flex max-w-[600px] flex-col justify-center self-start pr-8 text-3xl opacity-0 sm:text-4xl lg:justify-start lg:text-4xl xl:pr-12 xl:text-5xl 2xl:pr-0"
                    :class="{
                        'pt-12 md:pt-16 lg:pt-0': $slots.default,
                        [contentOffset.classes]: true,
                    }"
                    data-aos="fade-up"
                    data-aos-delay="100"
                />
            </div>
        </div>
    </div>
</template>

<style>
    @supports (animation-timeline: scroll()) {
        body.with-feature-image:not(.is-scrolled-down-slightly):not(.is-scrolled-down-more)
            .page-header {
            @apply text-white;
            text-shadow: 0 0 2em rgba(128, 128, 128, 0.4);
        }
    }

    body.page-home.with-feature-image:not(.is-scrolled-down-slightly):not(.is-scrolled-down-more)
        .page-header {
        @apply text-white;
    }

    .page-header {
        --feature-image-margin: 0;

        @apply text-dark;
        text-shadow: 0 0 2em rgba(128, 128, 128, 0);
    }

    .page-intro-area {
        min-height: calc(50vh + 100px);
    }

    :root {
        --animation-range: 50vh;
    }

    .page-header.faded {
        --feature-image-width: 100vw;

        @screen lg {
            --feature-image-width: calc(100vw / 16 * 14);
            --feature-image-margin: 220px;
        }

        @screen 2xl {
            --feature-image-width: calc(100vw / 16 * 11);
        }
    }

    .page-header.faded figure {
        margin-top: var(--feature-image-margin);
        width: var(--feature-image-width);
        margin-inline: auto;
        aspect-ratio: 16/9;

        &[data-aos^='fade'][data-aos^='fade'].aos-animate {
            opacity: 20%;
        }
    }

    @supports (animation-timeline: scroll()) {
        .page-header.faded figure {
            margin-top: 0;
            width: 100vw;
            animation: feature-image-transition linear both;
            animation-timeline: scroll(block root);
            animation-range: 0px var(--animation-range);

            &[data-aos^='fade'][data-aos^='fade'].aos-animate {
                opacity: 100%;
            }
        }

        .page-header.with-image .page-intro {
            animation: freeze-intro linear both;
            animation-timeline: scroll(block root);
            animation-range: 0 var(--animation-range);
        }
    }

    @keyframes feature-image-transition {
        0% {
            width: 100vw;
        }

        33.33% {
            width: 100vw;
        }

        100% {
            opacity: 20%;
            margin-top: var(--feature-image-margin);
            width: var(--feature-image-width);
        }
    }

    @keyframes freeze-intro {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(var(--animation-range));
        }
    }
</style>
